import React, { useEffect, useState } from "react";
import Layout from '../../components/layout';
import Services from './services';
import Banner from './banner';
import SEO from '../../components/Seo';
import { toast } from 'react-toastify';
import { getAll } from '../../slices/pageMeta';
import { useDispatch, useSelector } from '../../store';
import { getPageMeta } from "../../utils/common";
import { PageTypes } from "../../types/pageMeta";

const Index = () => {
  const dispatch = useDispatch();
  const [pageMetaData, setPageMetaData] = useState<any>(null);
  const { records: pageMetaRecord, } = useSelector(state => state.pageMeta);
  const id = pageMetaRecord?.data[0]?.id;
  const notify = (message: string) => toast.error(message, {
    position: toast.POSITION.BOTTOM_LEFT
  });
  async function fetchData() {
    const msg = `An error while retrieving banner content!`;
    try {
      const params = {
        "populate": "blocks,media,media.file",
        "filters[page][$eq]": PageTypes.Services,
      };
      dispatch(getAll(params));
    } catch (err) {
      console.log(err);
      notify(msg);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const pageRecord = Object.keys(pageMetaRecord?.data).length === 0 ? [] : pageMetaRecord?.data;
    const pageDataReduced = getPageMeta(pageRecord, PageTypes.Services);
    setPageMetaData(pageDataReduced);
  }, [id]);
  return (
    <Layout>
      <SEO title={pageMetaData?.metaTitle} description={pageMetaData?.metaDesc} />
      <Banner bannerData={pageMetaData} />
      <Services />
    </Layout>
  );
};

export default Index;